import React from 'react';
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import p10_goatAudio from "../audio/p09pajaro01Audio.m4a"
import p10VO from "../audio/p10VO.m4a"
import Footer from "../components/Footer"

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
const images = importAll(require.context('../images/', false, /.jpg$|.png/));

class Page10 extends React.Component {
  constructor(props){
    super(props)
    this.state = { active: false,
                   cencerro: images["p08_cencerro.png"],
                 }
  }
  playAudio = (x) => {
    const audioEl = document.getElementsByClassName("audio-element")[x]
     audioEl.play()
}
handleCencerroClick = () => {
  this.setState({ active: !this.state.active }, () => {
      if(this.state.active){
        setTimeout( () => {this.setState({cencerro: images["resetBotonTrans.png"]})}, 1000 );
      }
      else { this.setState({cencerro: images["p10_cencerro.png"]}) }
    })
}
  render() {
    return (
      <div className="main-container">
      <Link to="/Page09">
        <div className="page-turner">
          <img style={{transform: "scaleX(-1)", opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
        </div>
      </Link>
      <Link to="/Page11">
        <div className="page-turner right-page-pos">
        <img style={{opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
        </div>
      </Link>
        <div className="centered-container">
          <div className="backContainer004">
          <StaticQuery
            query={graphql`
              query {
                imageOne: file(relativePath: { eq: "p10_back.jpg" }) {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            `}
      render={data => (
        <Img fluid={data.imageOne.childImageSharp.fluid} />
      )}
    />
          </div>
          <div className="p10Cuentamelo"  onClick={() => this.playAudio(1)}>
            <img className="hw" src={images["botonCuentamelo.png"]} alt="" />
          </div>
          <div className={"cabraGroup" + (this.state.active ? " active" : "")} >
            <div className={"_idContainer157" + (this.state.active ? " active" : "")} >
              {/*<img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["cabraCSS.png"]}alt="" />*/}
            </div>
          </div>
          <div className="_idContainer158">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p10_pastoTop.png"]} alt="" />
          </div>
          <div className="_idContainer159 Basic-Text-Frame">
            <span>Y la cabra tiraba para el monte.</span>
          </div>
          {/*<div className="_idContainer160">
          <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["4265.png"]} alt="" />
        </div>*/}
        <div className={"_idContainer161" + (this.state.active ? " active" : "")} onClick={() => this.handleCencerroClick()}>
          <img className="cencerroReset _idGenObjectAttribute-1 _idGenObjectAttribute-2" src={this.state.cencerro} alt="" />
        </div>
        <audio className="audio-element">
          <source src={p10_goatAudio}></source>
        </audio>
        <audio className="audio-element">
          <source src={p10VO}></source>
        </audio>
      </div>
    <Footer />
    </div>
    )
  }
  }

export default Page10;
